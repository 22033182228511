/**
* Template Name: UpConstruction - v1.3.0
* Template URL: https://bootstrapmade.com/upconstruction-bootstrap-construction-website-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Set main reusable colors and fonts using CSS variables
# Learn more about CSS variables at https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
--------------------------------------------------------------*/
/* Fonts */
:root {
  --font-default: "Open Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-primary: "Roboto", sans-serif;
  --font-secondary: "Work Sans", sans-serif;
}

/* Colors */
:root {
  --color-default: #ffffff;
  --color-primary: #A48566;
  --color-secondary: #fefefe;
}

.MenuDisM {
  display: none;
}

/* Smooth scroll behavior */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: var(--font-default);
  color: var(--color-default);
  overflow-x: hidden;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: #e0e0e0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/*--------------------------------------------------------------
# Sections & Section Header
--------------------------------------------------------------*/
section {
  padding: 30px 15px 30px 15px;
  overflow: hidden;
}

.section-bg {
  background-color: #171717;
}

.section-header {
  text-align: center;
  padding-bottom: 50px;
}

.section-header h2 {
  font-size: 32px;
  font-weight: 700;
  position: relative;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #cfd9e7;
}

.section-header h2:before,
.section-header h2:after {
  content: "";
  width: 50px;
  height: 2px;
  background: var(--color-primary);
  display: inline-block;
}

.section-header h2:before {
  margin: 0 15px 10px 0;
}

.section-header h2:after {
  margin: 0 0 10px 15px;
}

.section-header p {
  color: var(--color-primary);
  font-size: larger;
  margin: 0 auto 0 auto;
}

@media (min-width: 1199px) {
  .section-header p {
    max-width: 60%;
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 50px;
  min-height: 30vh;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.breadcrumbs:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  inset: 0;
}

.breadcrumbs h2 {
  font-size: 45px;
  font-weight: 500;
  color: #fff;
  font-family: var(--font-secondary);
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-primary);
}

.breadcrumbs ol a {
  color: rgba(255, 255, 255, 0.8);
  transition: 0.3s;
}

.breadcrumbs ol a:hover {
  text-decoration: underline;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #fff;
  content: "/";
}

.menucircle {
  height: 310px;
  width: 310px;
  background-color: #000000;
  border: 2px dashed #b99e82c4;
  border-radius: 50%;
}

.menucircle .chlogo {
  padding-top: 25px;
}

.menucircle .chlogo img {
  width: 40% !important;
  background-color: #F0F0F0;
  border-radius: 50%;
  padding: 10px;
  border: 1px solid #3a3a3a;
}

@media only screen and (min-width: 300px) {
  .menu-img {
    width: 20vh;
  }
}

@media only screen and (min-width: 800px) {
  .menu-img {
    width: 30vh;
  }
}

@media only screen and (min-width: 1400px) {
  .menu-img {
    width: 40vh;
  }
}

.menucircle .ytname {
  padding-top: 20px;
  color: #f3f3f3;
  font-size: 16px;
}

.ytbutton {
  margin: 18px;
  padding: 5px;
  border: 1px solid red;
  border-radius: 5px;
  color: #d12222;
  font-size: 16px;
  width: fit-content;
}

.ytbutton:hover {
  background: red;
  padding: 6px;
  color: #f3f3f3;
}

.mobileapp {
  height: 100%;
  width: 300px;
  transition: 0.8s linear;

  background-color: #000000;
  border: 2px dashed var(--color-primary);
  border-radius: 10px;
}

.mobileapp:hover {
  border: 2px dashed #97C024;
}

.mobileapp .qr-code {
  margin: 20px 0 10px 0;
}

.mobileapp .qr-code img {
  width: 60%;
}

.mobileapp .name {
  padding: 15px;
}

.mobileapp i {
  font-size: 30px;
}

.mobileapp .downloadapp {
  background-color: #201d1c;
  color: #c4935b;
  padding: 10px 10px 10px 10px;
  border: none;
  /* margin: 50px 0 30px; */
  font-size: 15px;
  transition: 0.5s;
  /* transition: 0.3s linear;
  border-radius: 5px;
  height: 35px;
  margin: 5px; */
}

.mobileapp .downloadapp:hover {
  color: #facea5;
  border: 1px solid #9DC235;
  font-size: 18px;
  /* background: #000000;
  border-radius: 5px;
  border-color: #97c024;
  color: #97c024; */
}

.youtube {
  margin-bottom: 10px;
}

.responsive-iframe {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 2px dashed var(--color-primary);
  border-radius: 10px;
}

.youtube-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: content;
  transition: 0.3s;
}

.youtube-icon i {
  font-size: 40px;
}

.youtube-icon i {
  font-size: 40px;
}

.youtube-icon:hover i {
  color: red;
}

/*--------------------------------------------------------------
# Scroll top button
--------------------------------------------------------------*/
.scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: var(--color-primary);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.info-box {
  color: #444444;
  padding: 24px;
  text-align: center;
  height: 200px;
  color: white;
  border: 2px dashed var(--color-primary);
  border-radius: 12px;
}

.info-box a {
  text-decoration: none;
  color: unset;
}

.info-box i {
  color: white;
}

.info-box:hover {
  transition: all 0.8s;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: 2px 2px 2px #ffffff;
  border-radius: 20px;
}

.info-box h3 {
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.scroll-top i {
  font-size: 24px;
  color: var(--color-secondary);
  line-height: 0;
}

.scroll-top:hover {
  background: #ffc732;
  color: #fff;
}

.scroll-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  inset: 0;
  z-index: 999999;
  overflow: hidden;
  background: #fff;
  transition: all 0.6s ease-out;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #fff;
  border-color: var(--color-primary) transparent var(--color-primary) transparent;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1.5s linear infinite;
  animation: animate-preloader 1.5s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  z-index: 997;
  position: fixed;
  color: #000;
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  ;
  background-color: #f3f3f591;
  top: 0;
  left: 0;
  right: 0;
}

.header:hover {
  background-color: #1e232e7f;
  color: #fff;
}

.header a {
  color: #000000a2;
}

.header:hover a {
  color: #ffffffbe;
}

.header .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.header .logo h1 {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0;
  font-family: var(--font-primary);
}

.header .logo h1 span {
  color: #ffffff;
}

/*--------------------------------------------------------------
# Desktop Navigation
--------------------------------------------------------------*/
@media (min-width: 1280px) {
  .navbar {
    padding: 0;
  }

  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navbar li {
    position: relative;
  }

  .navbar>ul>li {
    white-space: nowrap;
    padding: 10px 0 10px 28px;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 3px;
    font-family: var(--font-primary);
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
    white-space: nowrap;
    transition: 0.3s;
    position: relative;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar>ul>li>a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -6px;
    left: 0;
    background-color: var(--color-primary);
    visibility: hidden;
    width: 0px;
    transition: all 0.3s ease-in-out 0s;
  }

  .navbar a:hover:before,
  .navbar li:hover>a:before,
  .navbar .active:before {
    visibility: visible;
    width: 100%;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: #fff;
  }

  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 28px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
  }

  .navbar .dropdown ul li {
    min-width: 200px;
  }

  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    color: var(--color-default);
    font-weight: 400;
  }

  .navbar .dropdown ul a i {
    font-size: 12px;
  }

  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover>a {
    color: var(--color-primary);
  }

  .navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }

  .navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
}

@media (min-width: 1280px) and (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

@media (min-width: 1280px) {

  .mobile-nav-show,
  .mobile-nav-hide {
    display: none;
  }
}

/*--------------------------------------------------------------
# Mobile Navigation
--------------------------------------------------------------*/
@media (max-width: 1279px) {
  .navbar {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    max-width: 400px;
    bottom: 0;
    transition: 0.3s;
    z-index: 9997;
  }

  .navbar ul {
    position: absolute;
    inset: 0;
    padding: 50px 0 10px 0;
    margin: 0;
    background: rgba(0, 0, 0, 0.8);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    font-family: var(--font-primary);
    font-size: 15px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.7);
    white-space: nowrap;
    text-transform: uppercase;
    transition: 0.3s;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover>a {
    color: #fff;
  }

  .navbar .dropdown ul,
  .navbar .dropdown .dropdown ul {
    position: static;
    display: none;
    padding: 10px 0;
    margin: 10px 20px;
    transition: all 0.5s ease-in-out;
    border: 1px solid #222428;
  }

  .navbar .dropdown>.dropdown-active,
  .navbar .dropdown .dropdown>.dropdown-active {
    display: block;
  }

  .mobile-nav-show {
    position: relative;
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
    z-index: 9999;
    padding-right: 10px;
  }

  .mobile-nav-hide {
    color: #fff;
    font-size: 32px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 9999;
  }

  .mobile-nav-active {
    overflow: hidden;
  }

  .mobile-nav-active .navbar {
    right: 0;
  }

  .mobile-nav-active .navbar:before {
    content: "";
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9996;
  }
}

/*--------------------------------------------------------------
# Get Started Section
--------------------------------------------------------------*/
.get-started .content {
  padding: 30px 0;
}

.get-started .content h3 {
  font-size: 36px;
  color: var(--color-secondary);
  font-weight: 600;
  margin-bottom: 25px;
  padding-bottom: 25px;
  position: relative;
}

.get-started .content h3:after {
  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 4px;
  background: var(--color-primary);
  left: 0;
  bottom: 0;
}

.get-started .content p {
  font-size: 14px;
}

.get-started .php-email-form {
  background: rgb(0, 0, 0);
  padding: 30px;
  border: #2B2A29 3px dashed;
  height: 100%;
  border-radius: 20px 20px 20px 0px;
}

@media (max-width: 575px) {
  .get-started .php-email-form {
    padding: 20px;
  }
}

.get-started .php-email-form h3 {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.get-started .php-email-form p {
  font-size: 14px;
  margin-bottom: 20px;
}

.get-started .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #df1529;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.get-started .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #059652;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.get-started .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.get-started .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #059652;
  border-top-color: #fff;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.get-started .php-email-form input,
.get-started .php-email-form textarea {
  border-radius: 10px;
  background-color: #2B2A29;
  box-shadow: none;
  border: none;
  font-size: 14px;
}

.get-started .php-email-form input:focus,
.get-started .php-email-form textarea:focus {
  border-color: var(--color-primary);
}

.get-started .php-email-form input {
  padding: 12px 15px;
}

.get-started .php-email-form textarea {
  padding: 12px 15px;
}

.get-started .php-email-form button[type=submit] {
  background: var(--color-primary);
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.get-started .php-email-form button[type=submit]:hover {
  background: rgba(254, 185, 0, 0.8);
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Constructions Section
--------------------------------------------------------------*/
.constructions {
  background-color: #171717;
  font-size: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
}

.constructions .card {
  cursor: pointer;
  background: rgb(0, 0, 0);
  position: relative;
  border-radius: 10px 10px 10px 0px;
  border: #d8d8d8c4 2px solid;
  transition: 0.5s;
  height: 100%;
}

.constructions .card:hover {
  border: #d39d67 2px dashed;
  border-radius: 15px;
  overflow: hidden;
  transform: scale(1.03);
}

.constructions .card:hover .card-img-top {
  transform: scale(1.07);
  transition: 0.7s;
}

.constructions .card .card-img-top {
  height: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.constructions .card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: #ffffff;
}

.constructions .card-body {
  cursor: pointer;
  border: 1px solid rgba(82, 86, 94, 0.2);
  background: rgb(0, 0, 0);
  position: relative;
  border-radius: 0 10PX 10PX 0PX;
  color: white;
}

.constructions .card-body .card-title {
  font-family: 'Merriweather', 'Times New Roman', serif;
  color: var(--color-primary);
  padding: 5px;
  font-size: 21px;
}

.constructions .card-body:hover .card-title {
  color: #cba37c;
  padding: 8px;
  transition: 0.5s;
}

.constructions .card-body .card-text {
  color: #ffffff;
  padding: 8px;
  font-size: 16px;
  padding: 10px;
}

.constructions .card-item .card-bg {
  min-height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.constructions .card-item .card-title {
  font-family: 'Merriweather', 'Times New Roman', serif;
  color: #ffffff;
  padding: 8px;
  font-size: 20px;
  display: none;
}

.constructions .card-item:hover .card-title {
  color: #cba37c;
  padding: 8px;
  transition: 0.5s;
}

.constructions .card-item .card-body {
  color: #ffffff;
  padding: 8px;
  font-size: 16px;
}

.constructions .card-item .card-body {
  padding: 30px;
}

.constructions .card-item h4 {
  margin-bottom: 15px;
}

.constructions .card-item p {
  margin: 0;
}

.constructions .childbb {
  left: 0px;
  top: 0;
  transition: 1.0s;
  width: 4%;
  padding: 10px;
  border-radius: 0px 5px 5px 0px;
  height: auto;
  background-color: var(--color-primary);
  margin-left: -15px;
}

.constructions .childbb .card-title {
  width: max-content;
  padding-left: 10px;
}

.constructions .card:hover .childbb {
  width: 100%;
  background-color: #a485666b;
  height: auto;
}

.constructions .childbb:hover .card-title {
  /* display: block; */
  color: #cba37c;
}

.constructions .childb1 {
  position: absolute;
  width: 95%;
  align-self: center;
  border: #ffffff7e 3px solid;
  left: 15px;
  height: 90%;
}

.grpchid:hover .childbb {
  width: 60%;
  background-color: #171717;
  height: auto;
}

.grpchid:hover .card-title {
  display: block;
}

.childb1:hover {
  border: #cba37c 3px solid;
  transition: 0.5s;
  border-radius: 0px 15px 15px 0px;
}

/* ==========Projects========= */

.info {
  margin-bottom: 50px;
}

.projects {
  background-color: #171717;
  font-size: 15px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 300;
}

.projects .card-item {
  cursor: pointer;
  /* background: rgb(0, 0, 0); */
  position: relative;
  /* border-radius: 10px 10px 10px 0px; */
  /* border: #d8d8d8c4 2px solid; */
  transition: 0.5s;
  height: 100%;
}

.projects .card-item:hover {
  /* transform: scale(1.03); */
  transition: 0.5s;
}

.card-title {
  font-size: 22px;
}

.projects .card-item .card-bg {
  min-height: 350px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.projects .card-item .card-body {
  padding: 30px;
}

.projects .card-item h4 {
  margin-bottom: 15px;
}

.projects .card-item p {
  margin: 0;
}

.text {
  position: absolute;
  left: 10px;
  bottom: -10px;
  height: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.text .title {
  background: #171717;
  padding: 5px 8px;
  display: inline-block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.05rem;
  /* border: 1px #030303 solid; */
}


.projects .childb1project {
  position: absolute;
  width: 92%;
  color: "#ffffff";
  align-self: center;
  left: 15px;
  height: 90%;
  margin-top: 10px;
  border: #ffffff7e 1px solid;
}

.childb1project:hover {
  height: 93%;
  border: var(--color-primary) 1px solid;
  transition: 0.5s linear;
  color: var(--color-primary);
  border-radius: 5px;
}

.projectbtn {
  background-color: #201d1c;
  color: #c4935b;
  padding: 5px 25px;
  border: none;
  margin: 50px 0 30px;
  font-size: 19px;
  transition: 0.5s;
}

.projectbtn:hover {
  color: #facea5;
  border: 1px solid #ca9c68;
  font-size: 20px;
}

.projects .portf-col {
  cursor: pointer;
  position: relative;
  transition: 0.5s;
  height: 100%;
  padding: 10px;
}

.projects .portf-col:hover .portfolio-item-img {
  overflow: hidden;
  /* border: 3px solid #ca9c68; */
}

.projects .portf-col:hover .portfolio-item-img img {
  transform: scale(1.07);
  transition: 0.5s;
}

.projects .portfolio-item-img {
  transition: 0.5s;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* border: 3px solid #ffffff96; */
}

.projects .portfolio-item-img img {
  width: 100%;
  height: 250px;
}

.projects .portfolio-item-title {
  width: fit-content;
  left: 15px;
  margin: -5px -30px 0 -30px;
  border: #ffffff7e 2px solid;
  position: relative;
  font-size: 21px;
  padding: 3px 24px;
  color: #cba37c;
  transition: 0.5s;
}

.projects .portf-col:hover .portfolio-item-title {
  border: #cf8234 2px dashed;
  border-radius: 8px;
  font-size: 22px;
  color: #f5d7bf;
}

.projects .portfolio-item-info {
  padding: 25px 50px 0;
  position: relative;
  right: 20px;
}

/* .projects .portfolio-item-img:after {
  content: '';
  position: absolute;
  left: 15px;
  top: 20px;
  right: 15px;
  bottom: 0;
  border: 3px solid #ffffff96;
  border-width: 3px 3px 0;
}

.projects .portfolio-item-info:before,
.projects .portfolio-item-info:after {
  content: '';
  position: absolute;
  height: 40px;
  width: 3px;
  background-color: #ffffff96;
  opacity: .9;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
  top: 0px;
}

.projects .portfolio-item-info::before {
  content: '';
  position: absolute;
  top: 15px;
  height: 3px;
  width: 650px;
  background-color: #ffffff96;

  content: '';
  position: absolute;
  height: 40px;
  left: 15px;
  top: 0;
  right: 15px;
  bottom: 15px;
  border: 3px solid #ffffff96;
  border-width: 0 3px 3px 3px;
} */

.projects .portfolio-item-params {
  color: #fff;
  margin-top: 15px;
  font-size: 16px;
  padding: 5px;
  transition: 0.5s;
}

.projects .portf-col:hover .portfolio-item-params {
  color: #ffffff;
}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/
.about {
  background-color: #080808;
}

.counts {
  background-color: #080808;
}

.about h2 {
  font-size: 48px;
  font-weight: 700;
  font-family: var(--font-secondary);
  margin: 30px 0;
}

@media (min-width: 991px) {
  .about h2 {
    max-width: 65%;
    margin: 0 0 80px 0;
  }
}

.about .our-story {
  padding: 40px;
  background-color: #080808;
}

@media (min-width: 991px) {
  .about .our-story {
    padding-right: 35%;
  }
}

.about .our-story h4 {
  text-transform: uppercase;
  font-size: 18px;
  color: #838893;
}

.about .our-story h3 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--color-secondary);
}

.about .our-story p:last-child {
  margin-bottom: 0;
}

.about ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.about ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.about ul i {
  font-size: 20px;
  margin-right: 4px;
  color: var(--color-primary);
}

.about .watch-video i {
  font-size: 32px;
  transition: 0.3s;
  color: var(--color-primary);
}

.about .watch-video a {
  font-weight: 600;
  color: var(--color-secondary);
  margin-left: 8px;
  transition: 0.3s;
}

.about .watch-video:hover a {
  color: var(--color-primary);
}

.about .about-img {
  min-height: 600px;
  background-size: cover;
  background-position: center;
}

@media (min-width: 992px) {
  .about .about-img {
    position: absolute;
    top: 0;
    right: 0;
  }
}

/*--------------------------------------------------------------
# Service Details Section
--------------------------------------------------------------*/
.service-details {
  background-color: #171717;
}

.service-details ul {
  list-style-type: disc;
}

.service-details ul li {
  padding: 3px;
}

.service-details .services-category li {
  display: inline-flex;
  line-height: 1;
  padding: 8px 0 8px 15px;
  border-left: 3px solid #d9e3e8;
  margin: 20px 10px;
  color: #e8e9ea;
  transition: 0.3s;
}

.service-details .services-list {
  padding: 10px 30px;
  border: 1px solid #d5d7da;
  margin-bottom: 20px;
}

.service-details .services-list a {
  display: inline-flex;
  line-height: 1;
  padding: 8px 0 8px 15px;
  border-left: 3px solid #d9e3e8;
  margin: 20px 10px;
  color: #e8e9ea;
  transition: 0.3s;
}

.service-details .services-list a.active {
  font-weight: 700;
  border-color: var(--color-primary);
}

.service-details .services-list a:hover {
  border-color: var(--color-primary);
}

.service-details .services-img {
  margin-bottom: 20px;
}

.service-details h3 {
  font-size: 28px;
  font-weight: 700;
  color: var(--color-secondary);
}

.service-details h4 {
  font-size: 20px;
  color: var(--color-secondary);
  font-weight: 700;
}

.service-details p {
  color: var(--color-secondary);
  font-size: 15px;
}


/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.contact .info-item {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  padding: 20px 0 30px 0;
}

.contact .info-item i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  font-size: 24px;
  line-height: 0;
  color: var(--color-primary);
  border-radius: 50%;
  border: 2px dotted #ffd565;
}

.contact .info-item h3 {
  font-size: 20px;
  color: #f7f7f7;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-item p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
  color: #f7f7f7;

}

.contact .php-email-form {
  width: 100%;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  padding: 30px;
  border-radius: 10p[];
}

.contact .php-email-form .form-group {
  padding-bottom: 20px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #df1529;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #059652;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #059652;
  border-top-color: #fff;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: var(--color-primary);
}

.contact .php-email-form input {
  height: 44px;
}

.addressBox {
  padding: 2px;
  border: rgba(153, 153, 153, 0.664) 3px dashed;
  float: left;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
  background: var(--color-primary);
  border: 0;
  padding: 10px 35px;
  color: #fff;
  transition: 0.4s;
  border-radius: 5px;
}

.contact .php-email-form button[type=submit]:hover {
  background: rgba(254, 185, 0, 0.8);
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.stickyDock {
  position: fixed;
  z-index: 1500;
  width: 100px;
  height: 160px;
  right: 0;
  border-radius: 5px 0px 0px 5px;
  bottom: 50%;
  -webkit-transition: .6s background-color cubic-bezier(.19, 1, .22, 1) 0s, -webkit-transform .6s cubic-bezier(.19, 1, .22, 1) 1.5s;
  transition: .6s background-color cubic-bezier(.19, 1, .22, 1) 0s, -webkit-transform .6s cubic-bezier(.19, 1, .22, 1) 1.5s;
  transition: .6s background-color cubic-bezier(.19, 1, .22, 1) 0s, transform .6s cubic-bezier(.19, 1, .22, 1) 1.5s;
  transition: .6s background-color cubic-bezier(.19, 1, .22, 1) 0s, transform .6s cubic-bezier(.19, 1, .22, 1) 1.5s, -webkit-transform .6s cubic-bezier(.19, 1, .22, 1) 1.5s;
  background-color: #f0f0f0;
}

.stickyDockUprLink {
  position: fixed;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  font-size: 40px;
  left: 10px;
  text-align: center;
  align-content: center;
  align-self: center;
  bottom: 4.5%;
  cursor: pointer;
  color: #ffffff;
  border: none;
  background-color: #00000062;
}

.fcont {
  background-color: #F0EFEF;
  width: 70%;
  margin-bottom: 15px;
  border-radius: 7px;
}

.stickyDockUprLink:hover {
  background-color: white;

  overflow: hidden;
}

.stickyDockUprLink:hover i {
  font-size: 43px;
  color: var(--color-primary);
  transition: 0.3s linear;
}

.stickyDockNav {
  position: fixed;
  z-index: 1500;
  color: rgb(244, 243, 243);
  display: none;
  left: 10px;
  font-size: 35px;
  top: 0%;
  -webkit-transition: .6s background-color cubic-bezier(.19, 1, .22, 1) 0s, -webkit-transform .6s cubic-bezier(.19, 1, .22, 1) 1.5s;
  transition: .6s background-color cubic-bezier(.19, 1, .22, 1) 0s, -webkit-transform .6s cubic-bezier(.19, 1, .22, 1) 1.5s;
  transition: .6s background-color cubic-bezier(.19, 1, .22, 1) 0s, transform .6s cubic-bezier(.19, 1, .22, 1) 1.5s;
  transition: .6s background-color cubic-bezier(.19, 1, .22, 1) 0s, transform .6s cubic-bezier(.19, 1, .22, 1) 1.5s, -webkit-transform .6s cubic-bezier(.19, 1, .22, 1) 1.5s;
}

.stickyDockNav:hover {
  color: rgb(246, 246, 246);
  transition: 0.8s;
  font-size: 38px;
}

.sidebarLinks {
  list-style-type: none;
  padding: 15px;
  font-family: 'Times New Roman', Times, serif;

}

.sidebarLinks h4 {
  font-size: 22px;
  color: #cacaca;
  font-family: 'Times New Roman', Times, serif;
}

.sidebarLinks h4:hover {
  color: #be9d55;
}

.socialIconSide {
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.socialIconSide:hover {
  color: #000;
  background-color: #e7f0ec;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
}

.headerSide {
  float: left;
}

.hero {
  overflow-x: hidden;
  padding: 0;
}

.hero .info {
  position: absolute;
  inset: 0;
  z-index: 2;
}

@media (max-width: 768px) {
  .hero .info {
    padding: 0 50px;
  }

  .stickyDockNav {
    display: block;
  }

  .MenuDisM {
    display: block;
  }

  .MenuMailSH {
    display: none;
  }

  .stickyDock {
    display: none;
  }
}

.hero .info h2 {
  color: #fff;
  margin-bottom: 30px;
  padding-bottom: 30px;
  font-size: 56px;
  font-weight: 700;
  position: relative;
}

.hero .info h2:after {
  content: "";
  position: absolute;
  display: block;
  width: 80px;
  height: 4px;
  background: var(--color-primary);
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.slideimgg {
  height: 100%;
  width: 100%;
  background-size: cover;
}

@media (max-width: 768px) {
  .hero .info h2 {
    font-size: 36px;
  }

  .hero .carousel {
    width: 100%;
    background-color: #171717;
    min-height: 10vh;
    position: relative;
  }

  .slideimgg {
    height: 40%;
    background-size: cover;
  }

  .hero {
    margin-top: 40px;

    height: 40vh;
  }

  .header {
    padding: 5px;
    background-color: #000;
  }

  .header a {
    color: white;
  }

  .hero .info {
    height: 40vh;
  }
}

.hero .info p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
}

.hero .info .btn-get-started {
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  color: #fff;
  border: 2px solid var(--color-primary);
}

.hero .info .btn-get-started:hover {
  background: var(--color-primary);
}

.formBox {
  background-color: #2B2A29;
}

.hero .carousel {
  width: 100%;
  min-height: 100vh;
  position: relative;
}

.carousel-item {
  position: absolute;
  inset: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  z-index: 1;
  transition-duration: 0.4s;
}

.hero .carousel-item::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  inset: 0;
}

#tsparticles {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
}

.image-box1 {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  overflow: hidden;
  inset: 0;
  position: absolute;
  animation: imageAnimation 11s infinite linear;
  transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  opacity: 1;
}

.image-box1 img {
  max-width: 100%;
}

.sidemenuimg {
  width: 100%;
}

.sidemenuimg1 {
  width: 100%;
  border-radius: 4px;
  background-color: #c2babab0;
  padding: 10px;
}

.info {
  background-color: #f8f3f316;
}

@keyframes imageAnimation {
  0% {
    background-blend-mode: multiply;
    animation-timing-function: ease-in;
    transform: scale(1.0);
  }

  25% {
    transform: scale(1.05);
  }

  50% {
    transform: scale(1.1);
  }

  75% {
    transform: scale(1.15);
  }

  100% {
    background-blend-mode: multiply;
    animation-timing-function: ease-out;
    transform: scale(1.20);
  }
}

@keyframes imageAnimation2 {
  0% {
    opacity: 0;
  }

  8% {
    opacity: 1;
    transform: scale(1.05);
  }

  17% {
    opacity: 1;
    transform: scale(1.1);
  }

  25% {
    opacity: 0;
    transform: scale(1.1);
  }

  100% {
    opacity: 0
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

.mainfooter {
  position: absolute;
  bottom: 25px;
  left: 0;
}

#footer {
  background: #0E0E0E;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}

#footer .footer-top {
  background: #000000;
  border-bottom: 1px solid #222222;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 28px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
}

#footer .footer-top .footer-info h3 span {
  color: #ffc451;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: #fff;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #292929;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #ffc451;
  color: #151515;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 5px;
  color: #ffc451;
  font-size: 15px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
  font-size: 16px;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  font-size: 16px;
}

#footer .footer-top .footer-links ul a:hover {
  color: #ffc451;
}

#footer .copyright {
  text-align: center;
  font-size: 15px;
  padding-top: 20px;
}

#footer .credits {
  text-align: center;
  font-size: 13px;
  color: #fff;
}

.center {
  position: absolute;
  top: 45%;
  left: 45%;
}

.loader {
  background-color: #171717;
  height: 100vh;
}